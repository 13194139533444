import styled, { css, keyframes } from 'styled-components'
import media from 'styled-media-query'
import * as TypographyStyles from 'components/Typography'
import * as SliderStyles from 'components/Slider/styles'

export const Wrapper = styled.header`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacings.medium} ${theme.spacings.xlarge};
    margin-top: ${theme.sizes.navbar};
    height: 100%;
    max-height: calc(100vh - ${theme.sizes.navbar});
    min-height: calc(100vh - ${theme.sizes.navbar});
    overflow: hidden;
    width: 100%;
    justify-content: center;
    margin-bottom: ${theme.spacings.large};

    ${media.lessThan('large')`
      height: calc(100vh - ${theme.sizes.navbar});
      padding: 0 0 ${theme.spacings.large} ${theme.spacings.small};
    `}

    ${media.lessThan('medium')`
      height: calc(70vh - ${theme.sizes.navbar});
      padding: 0;
      min-height: unset;
      padding-bottom: 110px;
      margin-bottom: 70px;
    `}

    @media screen and (device-aspect-ratio: 40/71) {
      height: calc(80vh - ${theme.sizes.navbar});
    }
  `}
`

export const TextWrapper = styled.div`
  width: 10%;
  max-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 10px;

  @media (max-width: 1510px) {
    margin-right: 20px;
  }

  @media (max-width: 1470px) {
    margin-right: 30px;
  }

  @media (max-width: 1367px) {
    margin-right: 0;
  }

  @media (max-width: 1225px) {
    margin-right: 35px;
  }

  @media (max-width: 1170px) {
    margin-right: 40px;
  }

  @media (max-width: 1080px) {
    margin-right: 50px;
  }

  @media (max-width: 990px) {
    margin-right: 65px;
  }

  ${media.lessThan('medium')`
    order: 2;
    width: 100%;
    top: 60px;
  `}
`

export const Title = styled(TypographyStyles.Title)`
  ${({ theme }) => css`
    font-size: clamp(${theme.font.sizes.large}, 7vh, ${theme.font.sizes.title});
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: -${theme.spacings.xxsmall};
    top: 100%;
    transform: rotate(-90deg);
    transform-origin: top left;
    z-index: ${theme.layers.base};
    width: calc(100vh - 100px);

    &:not(:last-child) {
      margin-bottom: 0;
    }

    span:last-child {
      display: inline-block;
      margin-top: ${theme.spacings.xxsmall};
    }

    ${media.lessThan('large')`
      margin-left: ${theme.spacings.xxsmall};
    `}

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.xlarge};
      width: 100vw;
      left: 180px;
      transform: rotate(0deg) translateX(-49%);
      top: unset;
      bottom: unset;
      text-align: center;
      margin-left: 0;
      margin-bottom: 0;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;  

      br {
        display: none;
      }
    `}

    @media screen and (device-aspect-ratio: 40/71) {
      transform: rotate(0deg) translateX(-57%);
    }

    @media (orientation: landscape) {
      ${media.between('medium', 'large')`
        left: ${theme.spacings.xsmall};
      `}
    }
  `}
`

const progressBar = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`

export const SliderWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex: 1;
    width: 100%;

    .gatsby-image-wrapper {
      height: 100%;

      img {
        object-position: top !important;
      }
    }

    ${media.greaterThan('huge')`
      max-width: 1600px;
      margin: 0 auto;
    `}

    ${media.lessThan('medium')`
      flex-direction: column;
    `}
  `}
`

export const Slider = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;

  img {
    height: 100%;
    width: 100%;
  }

  ${SliderStyles.Wrapper},
  .slick-slide,
  .slick-track {
    height: 100%;
    div {
      height: 100%;
    }
  }

  ${media.lessThan('large')`
    .slick-track {
      height: 640px;
    }
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const SliderMobile = styled(Slider)`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    height: 60vh;
    max-height: 400px;
  `}
`

export const ProgressBar = styled.ul`
  ${({ theme }) => css`
    background-color: ${theme.colors.grayscale.dark.info};
    list-style: none;
    position: absolute;
    height: 8px;
    width: 100%;
    top: 0;
    left: 0;

    li {
      display: none;
      height: 8px;
      width: 0;
      text-indent: -9999px;
    }

    .slick-active {
      display: block;
      background: ${theme.colors.primary.main};
      animation: ${progressBar} 5s linear;
      width: 100%;
    }
  `}
`
