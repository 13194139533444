import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.white};
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    padding: 0 ${theme.spacings.xlarge};
    height: ${theme.sizes.navbar};
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: ${theme.layers.menu};
    position: fixed;

    ${media.lessThan('large')`
      padding: 0 ${theme.spacings.small};
    `}

    ${media.lessThan('medium')`
      display: none;
    `}
  `}
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
`

export const LogoWrapper = styled.a`
  svg {
    height: 26px;
    width: auto;
  }
`

export const MenuNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MenuLink = styled(AnchorLink)`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.grayscale.light.title};
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.familySecondary};
    position: relative;
    outline: none;

    &:not(:last-child) {
      margin-right: 40px;
    }

    &::after {
      background-color: #2ee577;
      border-radius: 4px;
      content: '';
      position: absolute;
      width: 0;
      height: 4px;
      left: 50%;
      bottom: -8px;
      transform: translateX(-50%);
      transition: width 0.25s ease;
    }

    &:hover,
    &:focus {
      &::after {
        width: 100%;
      }
    }

    ${media.lessThan('large')`
      font-size: 15px;

      &:not(:last-child) {
        margin-right: ${theme.spacings.small};
      }
    `}

    ${media.lessThan('1000px')`
      font-size: 14px;

      &:not(:last-child) {
        margin-right: ${theme.spacings.xsmall};
      }
    `}
  `}
`
export const MenuLinkUrl = styled.a`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.grayscale.light.title};
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.familySecondary};
    position: relative;
    outline: none;

    &:not(:last-child) {
      margin-right: 40px;
    }

    &::after {
      background-color: #2ee577;
      border-radius: 4px;
      content: '';
      position: absolute;
      width: 0;
      height: 4px;
      left: 50%;
      bottom: -8px;
      transform: translateX(-50%);
      transition: width 0.25s ease;
    }

    &:hover,
    &:focus {
      &::after {
        width: 100%;
      }
    }

    ${media.lessThan('large')`
      font-size: 15px;

      &:not(:last-child) {
        margin-right: ${theme.spacings.small};
      }
    `}

    ${media.lessThan('1000px')`
      font-size: 14px;

      &:not(:last-child) {
        margin-right: ${theme.spacings.xsmall};
      }
    `}
  `}
`

export const LanguageWrapper = styled.div``

const languageButtonModifiers = {
  active: () => css`
    opacity: 1;
  `,
}

export const LanguageButton = styled.button`
  ${({ theme, active }) => css`
    color: ${theme.colors.neutral.black};
    cursor: pointer;
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.familySecondary};
    font-weight: ${theme.font.bold};
    opacity: 0.4;
    position: relative;
    outline: none;

    &:not(:last-child) {
      margin-right: ${theme.spacings.xsmall};
    }

    &::after {
      background-color: ${theme.colors.secondary.main};
      border-radius: 4px;
      content: '';
      position: absolute;
      width: 0;
      height: 4px;
      left: 50%;
      bottom: -8px;
      transform: translateX(-50%);
      transition: width 0.25s ease;
    }

    &:hover,
    &:focus {
      &::after {
        width: 100%;
      }
    }

    ${active && languageButtonModifiers.active()}
  `}
`

export const SpaceTrick = styled.span`
  ${media.lessThan('large')`
    display: none;
  `}
`
