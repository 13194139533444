import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import * as S from './Consultants.styles'
import maryKayAbout from 'assets/images/singleCases/maryKay/mary-kay-about.png'
import maryKayAbout2 from 'assets/images/singleCases/maryKay/mary-kay-about-2.png'

const Consultants = () => {
  const { formatMessage } = useIntl()

  return (
    <>
      <S.Line />
      <S.Wrapper>
        <S.Title>{formatMessage({ id: 'maryKay.consultants.title' })}</S.Title>
        <S.Content>
          <S.Left>
            <S.ImageWrapper>
              <S.Image src={maryKayAbout} />
            </S.ImageWrapper>
          </S.Left>
          <S.Right>
            <S.Text>
              <S.TextTitle>{formatMessage({ id: 'maryKay.consultants.subtitle' })}</S.TextTitle>
              <S.TextDescription>
                {formatMessage({ id: 'maryKay.consultants.description' })}
                <br /><br />
                {formatMessage({ id: 'maryKay.consultants.description2' })}
              </S.TextDescription>
            </S.Text>
            <S.ImageWrapper2>
              <S.Image src={maryKayAbout2} />
            </S.ImageWrapper2>
          </S.Right>
        </S.Content>
      </S.Wrapper>
    </>
  )
}

export default Consultants
