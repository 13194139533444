import React, { useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import * as S from './Depositions.styles'
import aspas from 'assets/images/singleCases/maryKay/quote.png'
import mock from './Depositions.mock.js'
import Slider from '../../../Slider'
import Aos from "aos"
import "aos/dist/aos.css"

const Depositions = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const { formatMessage } = useIntl()

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return <div className={`${className}_depositions`} style={{ ...style }} onClick={onClick} />
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return <div className={`${className}_depositions`} style={{ ...style }} onClick={onClick} />
  }

  const depositionsSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: false,
    centerPadding: '50px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }

  return (
    <S.Wrapper>
      <S.Title>{formatMessage({ id: 'maryKay.depositions.title' })}</S.Title>
      <S.Content data-aos="fade-up">
        <Slider settings={depositionsSettings}>
          {mock?.map((item, key) => (
            <S.Item key={key}>
              <S.ItemWrapper>
                <S.Left>
                  <S.ImageWrapper>
                    <S.Quote src={aspas} />
                  </S.ImageWrapper>
                  <S.Deposition>{item.deposition}</S.Deposition>
                  <S.Name>{item.name}</S.Name>
                  <S.Role>{item.role}</S.Role>
                </S.Left>
                <S.Right>
                  <S.Image src={item.image} />
                </S.Right>
              </S.ItemWrapper>
            </S.Item>
          ))}
        </Slider>
      </S.Content>
    </S.Wrapper>
  )
}

export default Depositions
