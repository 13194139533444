import imagem1 from 'assets/images/singleCases/maryKay/depositions/deposition-1.png'
import imagem2 from 'assets/images/singleCases/maryKay/depositions/deposition-1.png'

export default [
    {
      deposition: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est, suspendisse risus diam nisl ultrices eget viverra. Ut est eget viverra quisque nibh phasellus. Purus curabitur sed ornare ",
      name: 'Rosana Hida Bonazzi',
      role: 'Diretora de Vendas Mary Kay',
      image: imagem1
    },
    {
      deposition: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est, suspendisse risus diam nisl ultrices eget viverra. Ut est eget viverra quisque nibh phasellus. Purus curabitur sed ornare ",
      name: 'Rosana Hida Bonazzi',
      role: 'Diretora de Vendas Mary Kay',
      image: imagem2
    }
  ]