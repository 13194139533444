import React, { useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import * as S from './About.styles'
import Aos from "aos"
import "aos/dist/aos.css"

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  const { formatMessage } = useIntl()

  return (
    <>
      <S.BannerWrapper>
        <S.Banner />
      </S.BannerWrapper>
      <S.Wrapper>
        <S.Content>
          <S.Left>
            <S.LeftText>{formatMessage({ id: 'maryKay.about.title' })}</S.LeftText>
          </S.Left>
          <S.Right
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine">
            <S.Card>
              <S.CardTitle>{formatMessage({ id: 'maryKay.about.group.1.title' })}</S.CardTitle>
              <S.CardDescription>{formatMessage({ id: 'maryKay.about.group.1.description' })}</S.CardDescription>
            </S.Card>

            <S.Card>
              <S.CardTitle>{formatMessage({ id: 'maryKay.about.group.2.title' })}</S.CardTitle>
              <S.CardDescription>{formatMessage({ id: 'maryKay.about.group.2.description' })}</S.CardDescription>
            </S.Card>

            <S.Card>
              <S.CardTitle>{formatMessage({ id: 'maryKay.about.group.3.title' })}</S.CardTitle>
              <S.CardDescription>{formatMessage({ id: 'maryKay.about.group.3.description' })}</S.CardDescription>
            </S.Card>

            <S.Card>
              <S.CardTitle>{formatMessage({ id: 'maryKay.about.group.4.title' })}</S.CardTitle>
              <S.CardDescription>{formatMessage({ id: 'maryKay.about.group.4.description' })}</S.CardDescription>
            </S.Card>
          </S.Right>
        </S.Content>
      </S.Wrapper>
    </>
  )
}

export default About
