import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import * as TypographyStyles from 'components/Typography'
import * as SliderStyles from 'components/Slider/styles'

export const Line = styled.div`
  max-width: unset;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, .5);
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacings.medium} ${theme.spacings.xlarge};
    height: 100%;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    max-width: 1600px;
    margin: ${theme.spacings.medium} auto;

    @media (max-width: 1367px) {
      max-width: 1200px;
      margin: 0 auto;
    }

    ${media.lessThan('medium')`
      padding: ${theme.spacings.small};
    `}
  `}
`

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.title};
    width: 100%;
    margin-bottom: ${theme.spacings.medium};
    font-weight: ${theme.font.bold};

    @media (max-width: 1367px) {
      margin-bottom: ${theme.spacings.xsmall};
    }

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.xlarge};
    `}
  `}
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  @media (max-width: 1367px) {
    height: calc(100vh - 200px);
    overflow: visible;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

export const Left = styled.div`
  width: 46%;
  height: 680px;

  @media (max-width: 1367px) {
    height: 100%;
  }

  ${media.lessThan('medium')`
    width: 100%;
    height: 100%;
  `}
`

export const Right = styled.div`
  ${({ theme }) => css`
    width: 46%;
    height: 680px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @media (max-width: 1367px) {
      height: 100%;
      justify-content: flex-start;
    }

    ${media.lessThan('medium')`
      width: 100%;
      height: 100%;
      align-items: flex-start;
      margin-top: ${theme.spacings.xsmall};
    `}
  `}
`

export const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.lessThan('medium')`
    order: 2;
  `}
`

export const TextTitle = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    margin-bottom: ${theme.spacings.medium};
    font-weight: ${theme.font.bold};

    @media (max-width: 1367px) {
      margin-bottom: ${theme.spacings.xsmall};
    }

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.xlarge};
    `}
  `}
`

export const TextDescription = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    margin-bottom: ${theme.spacings.medium};
    font-weight: ${theme.font.regular};
    line-height: 1.4;
    color: rgba(0, 0, 0, .75);
  `}
`

export const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;

  ${media.lessThan('medium')`
    max-width: 230px;
  `}
`

export const ImageWrapper2 = styled.div`
  width: 100%;
  overflow: hidden;

  ${media.lessThan('medium')`
    max-width: 230px;
    order: 1;
    margin: 0 0 40px 70px;
  `}
`

export const Image = styled.img`
  width: 100%;
  max-height: unset;
  transition: transform 450ms;

  &:hover {
    transform: scale(1.2)
  }
`

