import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    overflow: hidden;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1600px;
    margin: 100px auto;

    ${media.lessThan('medium')`
      margin: ${theme.spacings.xlarge} auto;
      padding-bottom: ${theme.spacings.medium};
    `}
  `}
`

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.title};
    width: 100%;
    text-align: center;
    margin-bottom: ${theme.spacings.medium};
    font-weight: ${theme.font.bold};

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.xlarge};
    `}
  `}
`

export const Content = styled.div`
  width: 100%;
  padding: 10px 0 70px 0;
`

export const Item = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 40px 130px;
    
    ${media.lessThan('medium')`
      padding: 0 ${theme.spacings.medium} ${theme.spacings.medium};;
      background-color: #F5F5F5;
      padding-bottom: 120px;
    `}
  `}
`

export const ItemWrapper = styled.div`
  width: 100%;
  background-color: #F5F5F5;
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  padding: 0 0 50px 50px;

  ${media.lessThan('medium')`
    flex-direction: column;
    padding: 0;
  `}
`

export const Left = styled.div`
  ${({ theme }) => css`
    width: 50%;
    display: flex;
    flex-direction: column;

    ${media.lessThan('medium')`
      width: 100%;
      order: 2;
      margin-top: ${theme.spacings.medium};
    `}
  `}
`

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    width: 50px;
    margin-bottom: ${theme.spacings.medium};
  `}
`

export const Quote = styled.img`
  width: 100%;
`

export const Deposition = styled.p`
  ${({ theme }) => css`
    padding-right: 120px;
    margin-bottom: ${theme.spacings.medium};
    font-size: ${theme.font.sizes.medium};
    line-height: 1.4;

    ${media.lessThan('medium')`
      padding-right: ${theme.spacings.small};;
    `}
  `}
`

export const Name = styled.h3`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.bold};
  `}
`

export const Role = styled.span`
`

export const Right = styled.div`
  width: 50%;
  position: relative;
  bottom: 40px;
  right: 15px;

  ${media.lessThan('medium')`
    width: 100%;
    order: 1;
    bottom: unset;
    right: unset;
  `}
`

export const Image = styled.img`
  max-height: 100%;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`