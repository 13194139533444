import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import bannerMaryKay from 'assets/images/singleCases/maryKay/mary-kay-background.png'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacings.medium} ${theme.spacings.xlarge};
    height: 100%;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    max-width: 1600px;
    margin: ${theme.spacings.medium} auto;

    ${media.lessThan('medium')`
      margin: 0;
      padding: ${theme.spacings.small};
    `}
  `}
`

export const BannerWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 380px;
    margin-top: ${theme.spacings.xxlarge};
    overflow: hidden;
    max-width: unset;

    ${media.lessThan('medium')`
      background-position: center;
      height: 210px;
    `}
  `}
`

export const Banner = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-image: url(${bannerMaryKay});
    background-size: cover;
    background-position: center;
    transition: transform 450ms;

    &:hover {
      transform: scale(1.15);
    }
  `}
`

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.title};
    width: 100%;
    margin-bottom: ${theme.spacings.medium};
  `}
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

export const Left = styled.div`
  width: 40%;

  ${media.lessThan('medium')`
    width: 100%;
    margin-bottom: 50px;
  `}
`

export const LeftText = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xlarge};
    font-weight: ${theme.font.black};
    line-height: 1.2;
    padding-right: 130px;
    font-weight: ${theme.font.bold};

    ${media.lessThan('medium')`
      padding-right: 0;
    `}
  `}
`

export const Right = styled.div`
  width: 60%;
  max-width: 580px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${media.lessThan('medium')`
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  `}
`

export const ImageWrapper = styled.div`
  width: 100%;
`

export const Image = styled.img`
  width: 100%;
  max-height: 680px;
`

export const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &:nth-child(1) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-right: 1px solid rgba(0, 0, 0, 0.5);
    padding: 0 0 35px 0;
  }

  &:nth-child(2) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding: 0 0 35px 35px;
  }

  &:nth-child(3) {
    border-right: 1px solid rgba(0, 0, 0, 0.5);
    padding: 35px 0 0 0;
  }

  &:nth-child(4) {
    padding: 35px 0 0 35px;
  }

  ${media.lessThan('medium')`
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border: none;
      padding: 0;
      margin-bottom: 50px;
    }

    &:nth-child(4) {
      padding: 0;
      margin-bottom: 50px;
    }
  `}
`

export const CardTitle = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    width: 100%;
    max-width: 240px;
    margin-bottom: ${theme.spacings.xxsmall};
    font-weight: ${theme.font.bold};
  `}
`

export const CardDescription = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: rgba(0, 0, 0, .75);
    width: 100%;
    max-width: 230px;
    line-height: 1.4;
  `}
`