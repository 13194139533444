import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.small};
    max-width: ${theme.grid.container};
    margin: 0 auto;
    width: 100%;
  `}
`

export const WhatsAppBubbleWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  background: #2ee577;
  border-radius: 9999px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  bottom: 2%;
  right: 2%;
  box-shadow: 0 0 10px #00000020;
`