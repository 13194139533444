import React from 'react'
import Img from 'gatsby-image'
import Slider from 'components/Slider'
import useHeaderImagesMaryKay from 'hooks/useHeaderImagesMaryKay'
import useHeaderMobileMaryKay from 'hooks/useHeaderMobileMaryKay'
import { useIntl } from 'gatsby-plugin-intl'
import * as S from './Header.styles'

const settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  pauseOnHover: false,
  responsive: [],
  dots: true,
  appendDots: (dots) => <S.ProgressBar>{dots}</S.ProgressBar>,
}

const Header = () => {
  const { formatMessage } = useIntl()
  const images = useHeaderImagesMaryKay()
  const imagesMobile = useHeaderMobileMaryKay()

  return (
    <S.Wrapper>
      <S.SliderWrapper>
        <S.TextWrapper>
          <S.Title as="h2">
            <span>{formatMessage({ id: 'maryKay.heading.title' })}</span>
            <br />
            <span>{formatMessage({ id: 'maryKay.heading.complement' })}</span>
          </S.Title>
        </S.TextWrapper>
        <S.Slider>
          <Slider settings={{ ...settings }}>
            {images.map(({ childImageSharp, base }) => (
              <Img
                key={base}
                fluid={childImageSharp.fluid}
                alt={base?.split('.')[0]}
              />
            ))}
          </Slider>
        </S.Slider>
        <S.SliderMobile>
          <Slider settings={{ ...settings }}>
            {imagesMobile.map(({ childImageSharp, base }) => (
              <Img
                key={base}
                fluid={childImageSharp.fluid}
                alt={base?.split('.')[0]}
              />
            ))}
          </Slider>
        </S.SliderMobile>
      </S.SliderWrapper>
    </S.Wrapper>
  )
}

export default Header
