import { graphql, useStaticQuery } from 'gatsby'

const useHeaderMobileMaryKay = () => {
  const {
    allFile: { images },
  } = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "headerMobileMaryKay" }
        }
      ) {
        images: nodes {
          id
          base
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return images.sort(() => Math.random() - 0.5)
}

export default useHeaderMobileMaryKay
